import { useContext, useState } from "react";
import { DataTableContext } from "../../ContextApi/DataTableContext";
import { PostMandaysData } from "../../fetchApis/projects/mandays/PostMandaysData";
import LableAndInput from "../../Molecules/LableAndInput";
import Dropdown from "../../components/DropDown";
import Label from "../../Atom/Label";
import SweetAlert from "../../components/SweetAlert";
import { ProjectData } from "../../../utils/apis/projectData";
import { setProjects } from "../../../utils/slices/ProjectSlice";
import { useDispatch } from "react-redux";

const AddManDaysInduvisual = ({ viewRecord }) => {
  const [showDate, setShowDate] = useState("");
  const [updatedValue, setUpdatedValue] = useState([
    {
      project_id: viewRecord.id,
      update_date: "",
      total_man_days: "",
      total_achievement: "",
      status: "",
    },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const { isAddManDays, setIsAddManDays, setisEdit } =
    useContext(DataTableContext);

  const dispatch = useDispatch();

  const handleFilterOption = (name, value) => {
    setUpdatedValue((prevValues) =>
      prevValues.map((item) => ({
        ...item,
        [name]: value,
      }))
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue((prevValues) =>
      prevValues.map((item) => ({
        ...item,
        [name]: value,
      }))
    );
    if (name === "update_date") {
      setShowDate(value);
      const DateValue = new Date(value);
      const formattedDate = DateValue.toISOString();
      setUpdatedValue((prevValues) =>
        prevValues.map((item) => ({
          ...item,
          update_date: formattedDate,
        }))
      );
    }
  };
  const handleDateFocus = (e) => {
    const selectedDate = new Date(e.target.value);
    if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0) {
      e.target.value = "";
      setErrorMessage("Weekend days cannot be selected");
      e.preventDefault();
    } else {
      setErrorMessage("");
    }
  };

  const getMinDate = () => {
    let currentDate = new Date();
    let daysBack = 0;

    while (daysBack < 2) {
      if (currentDate.getDay() === 1) {
        currentDate.setDate(currentDate.getDate() - 3);
      } else if (currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() - 2);
      } else {
        currentDate.setDate(currentDate.getDate() - 1);
      }
      daysBack++;
    }

    return currentDate.toISOString().split("T")[0];
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  };

  const handleCancelUpdate = () => {
    setisEdit(false);
    setIsAddManDays(false);

    document.body.classList.remove("DrawerBody");
  };

  const PostUpdateEditData = async (data) => {
    try {
      const response = await PostMandaysData(data);
      const error = response?.ex?.response?.data?.map((item) => item);
      if (response?.status == true) {
        setIsAddManDays(false);
        SweetAlert({
          title: "operation perform sucessfully",
          text: "",
          icon: "success",
        });
        setisEdit(false);
        const projectData = await ProjectData();
        dispatch(setProjects(projectData));
      } else if (response?.ex?.response?.data[0]?.non_field_errors?.[0]) {
        SweetAlert({
          title: "Error",
          text: response?.ex?.response?.data[0]?.non_field_errors?.[0],
          icon: "error",
        });
      } else {
        SweetAlert({
          title: "Error",
          text: response?.ex?.response?.data?.error || "Something went wrong",
          icon: "error",
        });
      }
    } catch (error) {
      SweetAlert({
        title: "error",
        text: "",
        icon: "error",
      });
    }
    document.body.classList.remove("DrawerBody");
    // setIsDrawerOpen(false);
  };
  const validateFields = () => {
    const { update_date, total_man_days, total_achievement, status } =
      updatedValue[0];

    if (!update_date) {
      return "Date is required.";
    }
    if (!status) {
      return "Status is required.";
    }
    if (!total_man_days) {
      return "Man Days is required.";
    } else if (total_man_days == 0) {
      return "Man Days can not be 0.";
    }
    if (!total_achievement) {
      return "Achieve Target is required.";
    } else if (total_achievement == 0) {
      return "Achieve Target can not be 0.";
    }

    return null;
  };

  const handleEditUpdate = () => {
    const errorMessage = validateFields();
    if (!errorMessage) {
      PostUpdateEditData(updatedValue);
    } else {
      SweetAlert({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  return (
    <div className="">
      <h3 className="text-xl underline pb-4">
        Fill Man Days and Achieve Target
      </h3>
      <div className="flex items-center flex-wrap justify-center w-full rounded-sm">
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Project Code"}
            Inputvalue={viewRecord.project_code.toUpperCase()}
            desabled={true}
            inputClassName={"cursor-not-allowed p-2 border bg-[#f3eded]"}
            labelClassName={"pt-4 pb-2 text-left"}
            inputChange={handleInputChange}
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Project Name"}
            Inputvalue={viewRecord.name}
            desabled={true}
            inputClassName={"cursor-not-allowed p-2 border bg-[#f3eded]"}
            labelClassName={"pt-4 pb-2 text-left"}
            inputChange={handleInputChange}
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Date"}
            InputName={"update_date"}
            InputType={"date"}
            inputClassName={"p-2 border w-full"}
            labelClassName={"pt-4 pb-2 text-left"}
            Inputvalue={showDate}
            inputChange={handleInputChange}
            min={getMinDate()}
            max={getMaxDate()}
            InputOnFocus={handleDateFocus}
            required
          />
          {errorMessage && ( // Conditionally render the error message
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}
        </div>
        <div className="ProjectOperationEdit mt-4 text-left">
          <Label labelName={"Status"} className={"pb-2 mt-4"} />
          <Dropdown
            Option_Name={[
              "--Select Status--",
              // "Project Initiated",
              // "To Be Started",
              "In Progress",
              "Completed",
              "On Hold",
            ]}
            onChange={(name, value) => handleFilterOption(name, value)}
            className={"p-2 mt-2 border w-full"}
            name={"status"}
            id={"status induvisual"}
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Man Days"}
            InputName={"total_man_days"}
            InputType={"number"}
            inputClassName={"p-2 border"}
            labelClassName={"pt-4 pb-2 text-left"}
            Inputvalue={updatedValue.man_days}
            inputChange={handleInputChange}
            InputMax_lenght={2}
            required
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Achieve Target"}
            InputType={"number"}
            InputName={"total_achievement"}
            inputClassName={"p-2 border"}
            labelClassName={"pt-4 pb-2 text-left"}
            Inputvalue={updatedValue.total_achievement}
            inputChange={handleInputChange}
            InputMax_lenght={3}
            required
          />
        </div>
        <div className="flex pt-10">
          <button
            onClick={handleEditUpdate}
            className={
              "bg-green-300 p-4 m-2 flex items-center w-full rounded text-white hover:bg-green-500"
            }
          >
            Update
          </button>
          <button
            onClick={handleCancelUpdate}
            className={
              "bg-red-300 p-4 m-2 flex items-center w-full rounded text-white hover:bg-red-500"
            }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddManDaysInduvisual;
