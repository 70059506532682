import React, { useContext, useState,useEffect } from "react";
import LableAndInput from "../../Molecules/LableAndInput";
import { patchWithAuth, postWithAuth } from "../../provider/helper/axios";
import { EDITPROJECTREQUEST } from "../../../utils/constants/urls";
import { NotifiactionContext } from "../../ContextApi/NotificationContext";
import Loader from "../../Atom/Loader";
import SweetAlert from "../../components/SweetAlert";
import { DataTableContext } from "../../ContextApi/DataTableContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectData } from "../../../utils/apis/projectData";
import { setProjects } from "../../../utils/slices/ProjectSlice";
import ProjectSamplesTable from "./projectMultipleSampleTable/ProjectMultipleSampleTable";
import Popup from '../../Atom/Popup'

const SampleEdit = ({ viewRecord }) => {
  const [showDate, setShowDate] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updatedValue, setUpdatedValue] = useState();
  const [errors, setErrors] = useState({
    sample: "",
    remark: "",
    tentative_end_date: "",
  });
  const [updatedSampleCpi,setUpdatedSampleCpi] = useState([])

  const projectData = useSelector((store) => store.projectData.projects);
  const { setisEdit } = useContext(DataTableContext);
  const darkMode = useSelector((store) => store.darkMode.isDarkMode);
  const dispatchReRender = useDispatch();
  const { notificationList, setNotificationList } =
    useContext(NotifiactionContext);

  const currentProject = projectData.filter((item) => item.id == viewRecord.id);

  const IsMultipleSample = currentProject?.flatMap(
    (item) => item?.project_samples
  );
  const TotalSampleSize = updatedSampleCpi?.reduce((acc, item) => {
    return acc + Number(item.sample);
  }, 0);
  

  useEffect(() => {
        const formattedData = updatedSampleCpi?.map((item) => ({
          id: item.id,
          project: viewRecord.id,
          sample: item.sample,
          cpi: item.cpi,
          target_group: item.target_group,
          tentative_end_date:updatedValue?.tentative_end_date,
          remark: item.remark
        }));
        console.log("🚀 ~ formattedData ~ formattedData:", formattedData)
        setUpdatedSampleCpi(formattedData || []);
      }, [isPopupOpen,viewRecord]);
 
  const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "tentative_end_date") {
          const selectedDate = new Date(value);
          const tentativeStartDate = new Date(viewRecord.tentative_start_date);
          let errorMsg = "";
    
          if (selectedDate <= tentativeStartDate) {
            errorMsg = "End date must be after the start date.";
          } else if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0) {
            errorMsg = "Weekend dates are not allowed.";
          }
    
          setErrors((prevErrors) => ({
            ...prevErrors,
            tentative_end_date: errorMsg,
          }));
    
          setShowDate(value);
          setUpdatedValue({
            ...updatedValue,
            tentative_end_date: selectedDate.toISOString(),
          });
        }
    
        if (name === "sample") {
         
          let errorMsg = "";
          const numericValue = Number(value);
    
          if (value.trim() === "") {
            errorMsg = "Sample field cannot be empty.";
          } else if (numericValue === 0) {
            errorMsg = "Sample value cannot be 0.";
          }
          setErrors((prevErrors) => ({
            ...prevErrors,
            sample: errorMsg,
          }));
    
          setUpdatedValue({
            ...updatedValue,
            sample: value,
          });
        }
        if (name === "remark") {
          setUpdatedValue({
            ...updatedValue,
            remark: value,
          });
        }
      };
    
      const getMinDate = () => {
        let currentDate = new Date();
        let daysBack = 0;
    
        while (daysBack < 2) {
          if (currentDate.getDay() === 1) {
            currentDate.setDate(currentDate.getDate() - 3);
          } else if (currentDate.getDay() === 0) {
            currentDate.setDate(currentDate.getDate() - 2);
          } else {
            currentDate.setDate(currentDate.getDate() - 1);
          }
          daysBack++;
        }
    
        return currentDate.toISOString().split("T")[0];
      };
    
      const handleCancelUpdate = () => {
        setisEdit(false);
        document.body.classList.remove("DrawerBody");
      };
    
      // const validateFields = () => {
      //   let isValid = true;
      //   let errorMessage = "";
    
      //   if (!updatedValue?.remark) {
      //     isValid = false;
      //     errorMessage += "Reason for adjustment is required.\n";
      //   }
    
      //   if (!isValid) {
      //     SweetAlert({
      //       title: "Error",
      //       text: errorMessage.trim(),
      //       icon: "error",
      //     });
      //   }
    
      //   return isValid;
      // };

      const PostUpdateEditData = async (data) => {
        setLoader(true);
        let id = viewRecord?.id;
        const response = await patchWithAuth(EDITPROJECTREQUEST(id), data);
        if (response.status === true) {
          setLoader(false);
          SweetAlert({
            title: "Edit Request Sent Successfully",
            text: "",
            icon: "success",
          });
          setisEdit(false);
          const projectData = await ProjectData(); // Wait for the promise to resolve
          dispatchReRender(setProjects(projectData));
        }
        setNotificationList([...notificationList, response?.data]);
      };
    
    const handleEditUpdate = () => {
      // if (!validateFields() || errors.tentative_end_date) return;
      
      console.log("Final POST Data:", updatedSampleCpi || updatedValue); // Debugging the final data structure
      PostUpdateEditData(updatedSampleCpi); // Submit the data
    };

      const handlePopupClose = (updatedData) => {
        setIsPopupOpen(false);
        if (updatedData) {
          setUpdatedSampleCpi(updatedData)
        }
      };
      const checkIfMultipleSample = () =>{        
        if(IsMultipleSample.length > 0){
          setIsPopupOpen(true);
        }
      }
      const combinedRemarks = updatedSampleCpi?.map((data) => data.remark)
      .join(", ");

  return (
    <div
      className={`${
        darkMode ? "bg-black text-white" : "bg-gray-50 text-black"
      }`}
    >
      <h3 className="text-xl underline pb-4">Project Edit Request</h3>
      <div className="flex items-center flex-wrap justify-center w-full rounded-sm">
      <div className="ProjectOperationEdit hidden">
          <LableAndInput
            labelName={"Project Code"}
            Inputvalue={viewRecord.project_code.toUpperCase()}
            desabled={true}
            inputClassName={"cursor-not-allowed p-2 border bg-[#f3eded]"}
            labelClassName={"pt-4 pb-2 text-left"}
            inputChange={handleInputChange}
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Project Name"}
            Inputvalue={viewRecord.name}
            desabled={true}
            inputClassName={"cursor-not-allowed p-2 border bg-[#f3eded]"}
            labelClassName={"pt-4 pb-2 text-left"}
            inputChange={handleInputChange}
          />
        </div>
        <div className="ProjectOperationEdit">
          <LableAndInput
            labelName={"Date End Required(Tentative)"}
            InputName={"tentative_end_date"}
            InputType={"date"}
            inputClassName={"p-2 border w-full"}
            labelClassName={"pt-4 pb-2 text-left"}
            Inputvalue={showDate}
            inputChange={handleInputChange}
            min={getMinDate()}
          />
          {errors.tentative_end_date && (
            <p className="text-red-500 text-sm">{errors.tentative_end_date}</p>
          )}
        </div>
        <div className="ProjectOperationEdit">
        <LableAndInput
          labelName={"Revised Target Required (Sample)"}
          InputType={"number"}
          InputName={"sample"}
          inputClassName={"p-2 border"}
          labelClassName={"pt-4 pb-2 text-left"}
          Inputvalue={TotalSampleSize || viewRecord?.sample || updatedValue?.sample}
          inputChange={handleInputChange}
          InputMax_lenght={3}
          min={1}
          InputOnFocus={checkIfMultipleSample}
        />         
          {errors.sample && (
            <p className="text-red-500 text-sm">{errors.sample}</p>
          )}
        </div>

        {/* Modal */}
        {isPopupOpen && (
          <Popup>            
                  <ProjectSamplesTable  onClose={handlePopupClose} projectSamples={IsMultipleSample} />
          </Popup>
        )}
        <div className="ProjectOperationEdit">
           <LableAndInput
            labelName={"Remark"}
            InputType={"text"}
            InputName={"remark"}
            inputClassName={"p-2 border"}
            labelClassName={"pt-4 pb-2 text-left"}
            Inputvalue={combinedRemarks || updatedValue?.remark}
            inputChange={handleInputChange}
          />
        </div>
        <div className="flex pt-10">
          <button
            onClick={handleEditUpdate}
            className={
              "bg-green-300 p-4 m-2 flex items-center w-full rounded text-white hover:bg-green-500"
            }
          >
            Update
          </button>
          <button
            onClick={handleCancelUpdate}
            className={
              "bg-red-300 p-4 m-2 flex items-center w-full rounded text-white hover:bg-red-500"
            }
          >
            Cancel
          </button>
        </div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {loader ? <Loader /> : ""}
        </div>
      </div>
    </div>
  );
};

export default SampleEdit;
